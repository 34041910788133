import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, IonToast, isPlatform, useIonLoading } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { GooglePlus } from '@ionic-native/google-plus';

import Menu from './components/Menu';
import DuesPage from './pages/DuesPage';
import TransactionsPage from './pages/TransactionsPage';
import PreferencesPage from './pages/PreferencesPage';
import LoginPage from './pages/LoginPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import TransactionPage from './pages/TransactionPage';
import { useOpenOverlays } from './hooks/overlays';
import ContactsPage from './pages/ContactsPage';
import DashboardPage from './pages/DashboardPage';
import ProtectedRoute from './components/ProtectedRoute';

const TIME_TO_EXIT = 1000;

const App: React.FC = () => {
  const auth = firebase.auth();
  const [user, userLoading] = useAuthState(auth);
  const history = useHistory();
  const app = useRef<HTMLIonAppElement>(null);

  const [presentLoading, dismissLoading] = useIonLoading();

  const handleLogout: MouseEventHandler = async () => {
    presentLoading('Signing Out...');
    if (isPlatform('cordova')) {
      try {
        await GooglePlus.disconnect();
      } catch (error) {
        console.error('Unable to logout from google plus', error);
      }
    }

    dismissLoading();
    await auth.signOut();
    history.replace('/login');
  };

  const exitTime = useRef<number>(0);
  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  // Handling open overlays

  const openPopovers = useOpenOverlays(app, "ion-popover");
  const openAlerts = useOpenOverlays(app, "ion-alert");
  const openModals = useOpenOverlays(app, "ion-modal");
  const openLoadings = useOpenOverlays(app, "ion-loading");

  useEffect(() => {
    return history.listen(async () => {
      if (openPopovers.getTop()) {
        const popoverCount = await openPopovers.dismissAll();
        console.log(`Dismissed ${popoverCount} popovers`);
      }

      if (openAlerts.getTop()) {
        const alertCount = await openAlerts.dismissAll();
        console.log(`Dismissed ${alertCount} alerts`);
      }

      if (openModals.getTop()) {
        const modalCount = await openModals.dismissAll();
        console.log(`Dismissed ${modalCount} modals`);
      }

      if (openLoadings.getTop()) {
        const loadingCount = await openLoadings.dismissAll();
        console.log(`Dismissed ${loadingCount} loadings`);
      }
    });
  }, [history]);

  useEffect(() => {
    Plugins.App.addListener("backButton", async () => {
      if (openPopovers.getTop()) {
        await openPopovers.dismissTop();
        return;
      }

      if (openAlerts.getTop()) {
        await openAlerts.dismissTop();
        return;
      }

      if (openModals.getTop()) {
        await openModals.dismissTop();
        return;
      }

      const path = history.location.pathname;
      if (path === '/dashboard'
        || path === '/dues'
        || path === '/transactions'
        || path === '/contacts'
        || path === '/preferences'
        || path === '/login') {

        if (Date.now() - exitTime.current < TIME_TO_EXIT) {
          Plugins.App.exitApp();
        }
        else {
          exitTime.current = Date.now();
          setToast({
            show: true,
            message: 'Press again to exit',
          });
        }
      }
      else {
        history.goBack();
      }
    });
  }, []);

  return (
    <IonApp ref={app}>
      <IonRouterOutlet>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login" exact component={LoginPage} />
          <Route>
            <IonSplitPane contentId="main">
              <Menu onLogout={handleLogout} />
              <IonRouterOutlet id="main">
                <Switch>
                  <ProtectedRoute path="/dashboard" exact component={DashboardPage} />
                  <ProtectedRoute path="/dashboard/:contactId" exact component={TransactionsPage} />
                  <ProtectedRoute path="/dashboard/:contactId/:id" exact component={TransactionPage} />
                  <ProtectedRoute path="/dues" exact component={DuesPage} />
                  <ProtectedRoute path="/dues/:id" exact component={TransactionPage} />
                  <ProtectedRoute path="/transactions" exact component={TransactionsPage} />
                  <ProtectedRoute path="/transactions/:id" exact sensitive component={TransactionPage} />
                  <ProtectedRoute path="/contacts" exact component={ContactsPage} />
                  <ProtectedRoute path="/contacts/:contactId/transactions" exact component={TransactionsPage} />
                  <ProtectedRoute path="/contacts/:contactId/transactions/:id" exact component={TransactionPage} />
                  <ProtectedRoute path="/preferences" exact component={PreferencesPage} />
                </Switch>
              </IonRouterOutlet>
            </IonSplitPane>
          </Route>
        </Switch>
      </IonRouterOutlet>
      <IonToast
        isOpen={toast.show}
        duration={TIME_TO_EXIT}
        message={toast.message}
        onDidDismiss={() => {
          setToast({ show: false, message: '' });
        }} />
    </IonApp>
  );
};

export default App;
