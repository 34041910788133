import { IonButtons, IonContent, IonHeader, IonLoading, IonMenuButton, IonPage, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router';
import firebase from 'firebase/app';
import LoginContainer from '../components/LoginContainer';
import { GooglePlus } from '@ionic-native/google-plus';
import { useState } from 'react';

const LoginPage: React.FC = () => {

  const auth = firebase.auth();
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);
  const [authUser, authLoading] = useAuthState(auth);

  const handleSignIn = (form: any) => {
    localStorage.setItem('email', form.email);
    signInWithEmailAndPassword(form.email, form.password);
  };

  const [signingIn, setSigningIn] = useState(false);
  const signInWithGoogle = async () => {
    if (isPlatform('cordova')) {
      try {
        const res = await GooglePlus.login({
          webClientId: '671797981851-e00nn3656k4t8v3932o5uueh4d01b8do.apps.googleusercontent.com',
          offline: false,
        });
  
        setSigningIn(true);

        const googleCredential = firebase.auth.GoogleAuthProvider.credential(res.idToken);
        await auth.signInWithCredential(googleCredential);

        setSigningIn(false);
      } catch (error) {
        console.log('Error login with google plus', error);
        setSigningIn(false);
      }
    }
    else {
      const provider = new firebase.auth.GoogleAuthProvider();
      await auth.signInWithPopup(provider);
    }
  };

  if (!authLoading && authUser) {
    return <Redirect to="/dashboard" push={false} />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Login</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonLoading isOpen={loading || authLoading || signingIn} message="Signing In..." />
        {
          !authLoading && <LoginContainer signIn={handleSignIn} signInWithGoogle={signInWithGoogle} error={error} />
        }
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
