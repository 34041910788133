import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

export interface Contact {
  _id: string;
  uid: string;
  name: string;
  phone?: string;
}

type UseContactsOutputType = [Contact[] | undefined, boolean, (contactId?: string) => Contact | undefined];
type UseContactOutputType = {
  contact: Contact | undefined,
  loading: boolean,
  update: (data: Contact) => Promise<Contact>,
  insert: (data: Contact) => Promise<Contact | undefined>,
  deleteRecord: () => Promise<boolean>,
};

const useContacts = (): UseContactsOutputType => {
  const firestore = firebase.firestore();
  const [user, userLoading] = useAuthState(firebase.auth());
  const [contacts, setContacts] = useState<Contact[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && !userLoading) {
      return firestore.collection('contacts').where('uid', '==', user.uid).onSnapshot(ref => {
        const docs = ref.docs.map(doc => {
          return {
            _id: doc.id,
            ...doc.data(),
          } as Contact;
        });

        setContacts(docs);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    }
  }, [user, userLoading]);

  const getContact = (contactId?: string): Contact | undefined => {
    return contacts && contactId ? contacts.find(c => c._id === contactId) : undefined;
  };

  return [contacts, loading, getContact];
};

const useContact = (id?: string): UseContactOutputType => {
  const firestore = firebase.firestore();
  const [user, userLoading] = useAuthState(firebase.auth());

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<Contact>();

  const col = firestore.collection('contacts');
  const ref = col.doc(id);

  useEffect(() => {
    if (!id) {
      setLoading(false);
    }
    else if (user && !userLoading) {
      return ref.onSnapshot(ref => {
        const doc = {
          _id: ref.id,
          ...ref.data(),
        } as Contact;

        setContact(doc);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    }
  }, [user, userLoading, id]);

  const update = async (data: Contact) => {
    setLoading(true);
    const { _id, ...upd } = data;
    await ref.update(upd);
    setLoading(false);
    return data;
  };

  const insert = async (data: Contact) => {
    if (user) {
      setLoading(true);
      const { _id, ...upd } = data;
      upd.uid = user.uid;
      const out = await col.add(upd);
      const outDoc = await out.get();
      setLoading(false);
      return {
        _id: outDoc.id,
        ...outDoc.data(),
      } as Contact;
    }
  };

  const deleteRecord = async () => {
    if (user) {
      setLoading(true);
      await ref.delete();
      setLoading(false);
      return true;
    }
    return false;
  };

  return {
    contact,
    loading,
    update,
    insert,
    deleteRecord,
  };
};

export {
  useContacts,
  useContact,
};

