import moment from 'moment';
import firebase from 'firebase/app';
import _ from 'lodash';

const formatDate = (date: any, pattern?: string) => {
  if (!date) {
    return '';
  }

  if (date instanceof firebase.firestore.Timestamp) {
    return moment(date.toMillis()).format(pattern);
  }

  return moment(date).format(pattern);
};

const getMoment = (date: any) => {
  if (!date) {
    return null;
  }

  if (date instanceof firebase.firestore.Timestamp) {
    return moment(date.toMillis());
  }

  return moment(date);
};

const parseDate = (value?: any) => {
  if (value) {
    const mmt = moment(value);
    if (mmt.isValid()) {
      return mmt.toDate();
    }
  }
  return null;
};

const formatCurrency = (value?: number) => {
  return Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value || 0);
};

const parseNumber = (value?: any) => {
  if (value === '')
    return null;
  return _.toNumber(value);
};

export {
  formatDate,
  getMoment,
  parseDate,
  formatCurrency,
  parseNumber,
};
