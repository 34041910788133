import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react';
import { arrowDownOutline, arrowDownSharp, arrowUpOutline, arrowUpSharp, caretDown, caretUp, caretUpCircle } from 'ionicons/icons';
import { useLocation } from 'react-router';
import { Contact } from '../hooks/contacts';
import { Transaction } from '../hooks/transactions';
import { formatCurrency, formatDate } from '../utils/util';

interface TransactionItemProps {
  data?: Transaction;
  contact?: Contact;
  showContactName?: boolean;
}

const TransactionItem: React.FC<TransactionItemProps> = ({ data, contact, showContactName }) => {
  const location = useLocation();

  if (!data) {
    return null;
  }

  const isBorrowed = data.tranType === 'B';

  return (
    <IonItem lines="full" button detail routerLink={`${location.pathname}/${data._id}`}>
      {/* <IonIcon
        slot="start"
        color={isBorrowed ? 'success' : 'danger'}
        ios={isBorrowed ? arrowDownOutline : arrowUpOutline}
        md={isBorrowed ? arrowDownSharp : arrowUpSharp}
      /> */}

      <IonLabel>
        <h1>
          {formatCurrency(data.amount)} <IonIcon color={isBorrowed ? 'success' : 'danger'} icon={isBorrowed ? caretDown : caretUp} />
        </h1>
        {showContactName && <p>{contact?.name}</p>}
        <p><IonText>{formatDate(data.dueDate, '[Due on] MMM D, YYYY')}</IonText></p>
        <p><IonText>{data.remarks}</IonText></p>
      </IonLabel>
    </IonItem>
  );
};

export default TransactionItem;
