import { IonIcon, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { callOutline, callSharp } from "ionicons/icons";
import React from "react";
import { Contact } from "../hooks/contacts";

interface ContainerProps {
  contacts?: Contact[];
  onClickContact?: (data: Contact) => void;
}

const ContactsContainer: React.FC<ContainerProps> = ({ contacts, onClickContact }) => {
  const handleClickContact = (data: Contact) => {
    if (onClickContact) {
      onClickContact(data);
    }
  };

  return (
    <IonList>
      {
        contacts && contacts.map(rec => (
          <IonItem key={rec._id} lines="full" button onClick={() => handleClickContact(rec)}>
            <IonLabel>
              <h2>{rec.name}</h2>
              <p><IonText><IonIcon ios={callOutline} md={callSharp}></IonIcon></IonText> {rec.phone || 'N/A'}</p>
            </IonLabel>
          </IonItem>
        ))
      }
    </IonList>
  );
};

export default ContactsContainer;
