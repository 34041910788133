import { IonButton, IonCard, IonCardContent, IonIcon, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import firebase from 'firebase/app';
import { logoGoogle } from "ionicons/icons";
import { useState } from "react";
import styles from './LoginContainer.module.css';

interface LoginForm {
  email: string;
  password: string;
}

interface LoginContainerProps {
  signIn: (cred: LoginForm) => void;
  signInWithGoogle: () => Promise<void>;
  error: Error | firebase.FirebaseError | undefined;
}

const LoginContainer: React.FC<LoginContainerProps> = ({signIn, error, signInWithGoogle}) => {

  const lsEmail = localStorage.getItem('email');
  const [form, setForm] = useState<LoginForm>({
    email: lsEmail || '',
    password: ''
  });

  const updateField = (field: string, value: any) => {
    setForm({
      ...form,
      [field]: value
    });
  };

  return (
    <IonCard>
      <IonCardContent>
        <IonItem lines="full">
          <IonInput type="email" autofocus value={form.email} onIonChange={e => updateField('email', e.detail.value)} placeholder="Email"></IonInput>
        </IonItem>

        <IonItem lines="full">
          <IonInput type="password" value={form.password} onIonChange={e => updateField('password', e.detail.value)} placeholder="Password"></IonInput>
        </IonItem>

        {
          error && (
            <IonItem lines="none">
              <IonText color="danger">{error?.message}</IonText>
            </IonItem>
          )
        }

        <div className={styles.actionButtonContainer}>
          <IonButton expand="block" onClick={() => signIn(form)}>
            <IonLabel>Login</IonLabel>
          </IonButton>
        </div>

        <div className={styles.actionButtonContainer}>
          <IonButton expand="block" color="light" onClick={() => signInWithGoogle()}>
            <IonIcon slot="start" ios={logoGoogle}></IonIcon>
            <IonLabel>Sign In With Google</IonLabel>
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default LoginContainer;
