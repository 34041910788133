import { Redirect, Route, RouteProps } from "react-router";
import firebase from 'firebase/app';
import { useAuthState } from "react-firebase-hooks/auth";
import { IonLoading, IonSpinner } from "@ionic/react";
import React from "react";

const ProtectedRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const auth = firebase.auth();
  const [user, userLoading] = useAuthState(auth);
  return (
    <Route {...rest} render={props => {
      if (userLoading) {
        return (
          <div className="spinner-container">
            <IonSpinner color="primary" />
          </div>
        )
      }

      if (!user) {
        return <Redirect to="/login" push={false} />
      }

      return <Component {...props} />
    }}
    />
  )
};

export default ProtectedRoute;
