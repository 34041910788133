import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonProgressBar, IonRouterLink, IonRow, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import { arrowDownOutline, arrowDownSharp, arrowUpOutline, arrowUpSharp, closeOutline, closeSharp, saveOutline, saveSharp, swapVerticalOutline, swapVerticalSharp, trashOutline, trashSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Contact, useContact } from "../hooks/contacts";
import ContactForm from "./ContactForm";
import styles from './ContactContainer.module.css';
import { useTransactions } from "../hooks/transactions";
import _ from 'lodash';
import { formatCurrency } from "../utils/util";
import { Link, useLocation } from "react-router-dom";

interface ContactContainerProps {
  id?: string;
  onClose: (newContact?: Contact) => void;
  onShowTransactions?: (id: string) => void;
}

const init = {
  _id: '',
  uid: '',
  name: '',
};

const ContactContainer: React.FC<ContactContainerProps> = ({ id, onClose, onShowTransactions }) => {
  const { contact, loading, insert, update, deleteRecord } = useContact(id);
  const [txns, txnsLoading] = useTransactions([['contactId', '==', id || 'x']]);
  const [model, setModel] = useState<Contact>(init);
  const [backup, setBackup] = useState<Contact>({ ...init });

  const location = useLocation();

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const handleChange = (newModel: Contact) => {
    setModel(newModel);
  };

  const saveContact = async () => {
    presentLoading("Saving...");
    let upd;
    if (backup._id) {
      upd = await update(model);
    }
    else {
      upd = await insert(model);
    }
    dismissLoading();

    if (onClose && upd) {
      onClose(upd);
    }
  };

  const [presentToast] = useIonToast();
  const doDeleteContact = async () => {
    presentLoading("Deleting...");
    const deleted = await deleteRecord();
    dismissLoading();

    if (onClose && deleted) {
      onClose();
    }
  };

  const deleteContact = () => {
    const canDelete = !txns || txns.length === 0;
    if (!canDelete) {
      presentToast({
        message: 'Cannot delete. Transactions found.',
        duration: 1500,
        color: 'warning',
      });
      return;
    }

    presentAlert('Are you sure want to delete?', [
      {
        text: 'Yes',
        handler: () => doDeleteContact()
      },
      {
        text: 'No'
      }
    ]);
  };

  useEffect(() => {
    if (id === 'new') {
      setModel(init);
      setBackup({ ...init });
    }
    else if (contact) {
      setModel(contact);
      setBackup({ ...contact });
    }
  }, [id, contact]);

  const modified = !_.isEqual(model, backup);

  const totalAmount = backup._id && txns ? txns
    .map(txn => (txn.tranType === 'B' ? -1 : 1) * (txn.amount || 0))
    .reduce((p, n) => p + n, 0) : 0;

  const isNegative = totalAmount < 0;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contact</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onClose()}>
              <IonIcon slot="icon-only" ios={closeOutline} md={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading && <IonProgressBar type="indeterminate" />}
        <ContactForm model={model} onChange={handleChange} />
        {
          (backup._id && !txnsLoading) && (
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonIcon
                      slot="start"
                      color={isNegative ? 'success' : 'danger'}
                      ios={isNegative ? arrowDownOutline : arrowUpOutline}
                      md={isNegative ? arrowDownSharp : arrowUpSharp}
                    />
                    <IonLabel>
                      <h2>{formatCurrency(totalAmount)}</h2>
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol>
                  <IonItem button lines="none" detail={false} onClick={onShowTransactions ? () => onShowTransactions(backup._id) : undefined}>
                    <IonIcon slot="start" color="tertiary" ios={swapVerticalOutline} md={swapVerticalSharp} />
                    <IonLabel color="tertiary">
                      <h2>{txns?.length === 0 ? 'No' : txns?.length} Transaction{txns?.length == 1 ? '' : 's'}</h2>
                    </IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="actions-container">
                    <IonButton color="danger" fill="clear" onClick={deleteContact} disabled={modified}>
                      <IonIcon slot="start" ios={trashOutline} md={trashSharp} />
                      <IonLabel>Delete</IonLabel>
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )
        }
      </IonContent>
      
      {
        (!loading && modified) && (
          <IonFooter>
            <div className="actions-container">
              <IonButton className="action-button" onClick={saveContact} disabled={!model.name || !model.phone}>
                <IonIcon slot="start" ios={saveOutline} md={saveSharp} />
                <IonLabel>Save</IonLabel>
              </IonButton>
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default ContactContainer;
