import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonLoading, IonPage, IonTitle, IonToolbar, useIonAlert, useIonToast } from "@ionic/react";
import { saveOutline, saveSharp, trashOutline, trashSharp } from "ionicons/icons";
import _, { toNumber } from 'lodash';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import TransactionForm from "../components/TransactionForm";
import { Transaction, useTransaction } from "../hooks/transactions";
import { formatDate, parseDate } from "../utils/util";

const TransactionPage: React.FC = () => {
  const { id, contactId } = useParams<{ id: string, contactId?: string }>();
  const { transaction, loading, update, insert, deleteRecord } = useTransaction(id !== 'new' ? id : undefined);

  const history = useHistory();
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  // Get parent path
  const { pathname } = useLocation();
  const parentPath = pathname.substring(0, pathname.lastIndexOf('/'));

  const [backup, setBackup] = useState<Transaction>();
  const [model, setModel] = useState<Transaction>({ amount: 0, remarks: '' });

  const handleChangeForm = (obj: Transaction) => {
    setModel({
      ...obj,
      amount: obj.amount ? toNumber(obj.amount) : obj.amount,
    });
  };

  const modified = !_.isEqual(backup, model);

  useEffect(() => {
    if (id !== 'new' && transaction && !loading) {
      const obj: Transaction = {
        ...transaction,
        tranDate: formatDate(transaction.tranDate),
        dueDate: formatDate(transaction.dueDate),
      };

      setModel(obj);
      setBackup({ ...obj });
    }
  }, [transaction, loading, id]);

  useEffect(() => {
    if (id === 'new') {
      const obj: Transaction = {
        contactId: contactId!,
        tranType: 'L',
        tranDate: formatDate(new Date()),
        remarks: '',
      };

      setModel(obj);
      setBackup({ ...obj });
    }
  }, [id, contactId]);

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    try {
      const txn = {
        ...model,
        tranDate: parseDate(model.tranDate),
        dueDate: parseDate(model.dueDate),
        amount: Number(model.amount),
      } as Transaction;

      if (txn._id) {
        await update(txn);
        setSaving(false);
        presentToast('Saved', 800);
      }
      else {
        const out = await insert(txn);
        setSaving(false);
        presentToast('Saved', 800);
        if (out) {
          history.replace(`${parentPath}/${out._id}`);
        }
      }
    } catch (error) {
      presentToast({
        message: 'Unable to save ' + JSON.stringify(error),
        duration: 1000,
        color: 'danger',
      });
    }
  };

  const deleteTxn = async () => {
    presentAlert('Are you sure want to delete?', [
      {
        text: 'Yes',
        role: 'yes',
        handler: () => doDeleteTxn()
      },
      {
        text: 'No',
        role: 'no',
      }
    ]);
  };

  const doDeleteTxn = async () => {
    setSaving(true);
    try {
      const out = await deleteRecord();
      setSaving(false);
      if (out) {
        presentToast('Deleted', 800);
        history.replace(parentPath);
      }
    } catch(error) {
      presentToast({
        message: 'Unable to save ' + JSON.stringify(error),
        duration: 1000,
        color: 'danger',
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={parentPath} />
          </IonButtons>
          <IonTitle>Transaction</IonTitle>
          <IonButtons slot="end">
            {
              loading ? null : (
                modified ? (
                  <IonButton color="primary" onClick={save}>
                    <IonIcon ios={saveOutline} md={saveSharp}></IonIcon>
                  </IonButton>
                ) : (
                  backup?._id && (
                    <IonButton color="danger" onClick={deleteTxn}>
                      <IonIcon ios={trashOutline} md={trashSharp}></IonIcon>
                    </IonButton>
                  )
                )
              )
            }
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Transaction</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonLoading isOpen={loading || saving} message={(saving ? 'Saving...' : 'Loading...')} />

        <TransactionForm
          disabled={loading || saving}
          disableContact={!!contactId}
          model={model}
          onChange={handleChangeForm}
        />
      </IonContent>
      {
        (!loading && modified) && (
          <IonFooter>
            <div className="actions-container">
              <IonButton expand="block" color="primary" className="action-button" onClick={save}>
                <IonIcon slot="start" ios={saveOutline} md={saveSharp}></IonIcon>
              Save
            </IonButton>
            </div>
          </IonFooter>
        )
      }
    </IonPage>
  );
};

export default TransactionPage;
