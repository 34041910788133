import { IonIcon, IonItem, IonLabel, IonList, IonProgressBar, IonText } from "@ionic/react";
import { arrowDownOutline, arrowDownSharp, arrowUpOutline, arrowUpSharp, caretDown, caretUp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Contact, useContacts } from "../hooks/contacts";
import { Transaction, useTransactions } from "../hooks/transactions";
import { formatCurrency } from "../utils/util";
import NoTransactions from "./NoTransacions";

type ContactTransactionsMap = { [contactId: string]: Transaction[] };

const DashboardContainer: React.FC = () => {
  const [txns, txnsLoading] = useTransactions();
  const [contacts, contactsLoading, getContact] = useContacts();

  const [contactList, setContactList] = useState<Contact[]>([]);
  const [contactTxnsMap, setContactTxnsMap] = useState<ContactTransactionsMap>({});

  const location = useLocation();

  useEffect(() => {
    if (txns && !txnsLoading && contacts && !contactsLoading) {
      const ctmap: ContactTransactionsMap = {};
      for (let txn of txns) {
        const { contactId } = txn;
        if (contactId) {
          ctmap[contactId] = (ctmap[contactId] || []).concat([txn]);
        }
      }

      const txnContacts: Contact[] = [];
      for (let contactId of Object.keys(ctmap)) {
        const contact = getContact(contactId);
        if (contact) {
          txnContacts.push(contact);
        }
      }

      setContactList(txnContacts);
      setContactTxnsMap(ctmap);
    }
  }, [txns, txnsLoading, contacts, contactsLoading]);

  const getTotalAmoumt = (contactId: string) => {
    return contactTxnsMap[contactId]
      .map(txn => (txn.tranType === 'B' ? -1 : 1) * (txn.amount || 0))
      .reduce((p, n) => p + n, 0);
  };

  return (
    <React.Fragment>
      {txnsLoading && <IonProgressBar type="indeterminate" />}
      <IonList>
        {
          contactList && contactList.map(rec => {
            const totalAmount = getTotalAmoumt(rec._id);
            const isNegative = totalAmount < 0;
            return (
              <IonItem key={rec._id} button detail lines="full" routerLink={`${location.pathname}/${rec._id}`}>
                {/* <IonIcon
                  slot="start"
                  color={isNegative ? 'success' : 'danger'}
                  ios={isNegative ? arrowDownOutline : arrowUpOutline}
                  md={isNegative ? arrowDownSharp : arrowUpSharp}
                /> */}
                <IonLabel>
                  <h2>{rec.name}</h2>
                  <p>{formatCurrency(Math.abs(totalAmount))} <IonIcon color={isNegative ? 'success' : 'danger'} icon={isNegative ? caretDown : caretUp} /></p>
                </IonLabel>
              </IonItem>
            );
          })
        }
        {
          (!txnsLoading && !contactsLoading && txns?.length === 0) && (
            <NoTransactions showCreate />
          )
        }
      </IonList>
    </React.Fragment>
  );
};

export default DashboardContainer;
