import { IonList } from "@ionic/react";
import React from "react";
import { useContacts } from "../hooks/contacts";
import { Transaction } from "../hooks/transactions";
import DueItem from "./DueItem";

const DuesContainer: React.FC<{ transactions: Transaction[] }> = ({ transactions }) => {
  const [_1, _2, getContact] = useContacts();
  return (
    <IonList>
      {
        transactions.map(rec => (
          <DueItem key={rec._id} data={rec} contact={getContact(rec.contactId)} />
        ))
      }
    </IonList>
    
  );
};

export default DuesContainer;
