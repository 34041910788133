import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { useHistory } from "react-router";

const NoTransactions: React.FC<{ message?: string, showCreate?: boolean }> = ({ message, showCreate = false }) => {
  const history = useHistory();
  const handleCreateTxn = () => {
    history.replace('/transactions');
    history.push('/transactions/new');
  };

  return (
    <div>
      <IonItem lines="none" className="ion-text-center">
        <IonLabel>
          <p>{message || 'No transactions'}</p>
        </IonLabel>
      </IonItem>
      {
        showCreate && (
          <div className="actions-container">
            <IonButton fill="outline" onClick={handleCreateTxn}>Create Transaction</IonButton>
          </div>
        )
      }
    </div>
  )
};

export default NoTransactions;
