import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import {
  settingsOutline,
  settingsSharp,
  timeOutline,
  timeSharp,
  swapVerticalOutline,
  swapVerticalSharp,
  logOutOutline,
  logOutSharp,
  peopleOutline,
  peopleSharp,
  listOutline,
  listSharp,
} from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import './Menu.css';
import person from '../icons/person.svg';
import firebase from 'firebase/app';
import { MouseEventHandler } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

interface MenuProps {
  onLogout: MouseEventHandler;
}

const appPages: AppPage[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    iosIcon: listOutline,
    mdIcon: listSharp,
  },
  {
    title: 'Dues',
    url: '/dues',
    iosIcon: timeOutline,
    mdIcon: timeSharp,
  },
  {
    title: 'Transactions',
    url: '/transactions',
    iosIcon: swapVerticalOutline,
    mdIcon: swapVerticalSharp,
  },
];

const splPages: AppPage[] = [
  {
    title: 'Contacts',
    url: '/contacts',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
  },
  {
    title: 'Preferences',
    url: '/preferences',
    iosIcon: settingsOutline,
    mdIcon: settingsSharp,
  },
];

const Menu: React.FC<MenuProps> = ({onLogout}) => {
  const auth = firebase.auth();
  const [user] = useAuthState(auth);
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="menu-list">
          <IonMenuToggle autoHide={false}>
            <IonItem lines="none" button>
              <IonAvatar slot="start" className="person-avatar">
                <IonImg src={user?.photoURL || person} />
              </IonAvatar>
              <IonLabel>
                <h2>{user?.displayName}</h2>
                <p>{user?.email}</p>
              </IonLabel>
            </IonItem>
          </IonMenuToggle>

          <hr className="hr" />

          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <hr className="hr" />

          {splPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="root" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <IonMenuToggle autoHide={false}>
            <IonItem button lines="none" detail={false} onClick={onLogout}>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Logout</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
