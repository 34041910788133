import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel, IonList, useIonToast } from "@ionic/react";
import { personOutline, personSharp } from "ionicons/icons";
import { Contact } from "../hooks/contacts";
import { Contact as ContactSchema, Contacts, ContactField } from '@ionic-native/contacts';
import _ from "lodash";

interface ContactFormProps {
  model: Contact;
  onChange: (upd: Contact) => void;
}

const ContactForm: React.FC<ContactFormProps> = ({ model, onChange }) => {
  const handleChange = (field: string, value: any) => {
    if (onChange) {
      onChange({
        ...model,
        [field]: value,
      });
    }
  };

  const [presentToast] = useIonToast();
  const handleChooseContact = async () => {
    const con = new Contacts();
    const contactSchema = await con.pickContact();
    if (contactSchema) {
      const newContact: any = {};
      if (contactSchema.displayName && !model.name) {
        newContact.name = contactSchema.displayName;
      }

      if (contactSchema.phoneNumbers.length > 0) {
        newContact.phone = contactSchema.phoneNumbers[0].value;
      }
      else {
        presentToast({
          message: 'No phone numbers mapped',
          duration: 1500,
          color: 'danger',
        });
      }

      if (!_.isEmpty(newContact) && onChange) {
        onChange({
          ...model,
          ...newContact,
        });
      }
    }
  };

  return (
    <IonList lines="full">
      <IonItem>
        <IonLabel position="stacked">Name</IonLabel>
        <IonInput type="text" value={model.name} onIonChange={e => handleChange('name', e.detail.value)} required autofocus />
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Phone</IonLabel>
        <IonInput type="tel" value={model.phone} onIonChange={e => handleChange('phone', e.detail.value)} required />
        <IonButtons slot="end">
          <IonButton onClick={handleChooseContact}>
            <IonIcon ios={personOutline} md={personSharp}></IonIcon>
          </IonButton>
        </IonButtons>
      </IonItem>
    </IonList>
  );
};

export default ContactForm;
