import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getMoment } from '../utils/util';

export interface Transaction {
  _id?: string;
  uid?: string;
  tranType?: 'L' | 'B',
  contactId?: string;
  amount?: number;
  tranDate?: any;
  dueDate?: any;
  remarks: string | '';
}

type UseTransactionsOutputType = [Transaction[] | undefined, boolean];
type UseTransactionOutputType = {
  transaction: Transaction | undefined,
  loading: boolean,
  update: (data: Transaction) => Promise<Transaction>,
  insert: (data: Transaction) => Promise<Transaction | undefined>,
  deleteRecord: () => Promise<boolean>,
};

const useTransactions = (filters?: [string, firebase.firestore.WhereFilterOp, any][]): UseTransactionsOutputType => {
  const firestore = firebase.firestore();
  const [user, userLoading] = useAuthState(firebase.auth());

  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>();
  const col = firestore.collection('transactions');

  useEffect(() => {
    if (user && !userLoading) {
      let newRef = col.where('uid', '==', user.uid);
      if (filters) {
        for (let filter of filters) {
          newRef = newRef.where(filter[0], filter[1], filter[2]);
        }
      }

      return newRef.onSnapshot(ref => {
        const docs = ref.docs.map(doc => {
          return {
            _id: doc.id,
            ...doc.data(),
          } as Transaction;
        })
        .sort((a, b) => {
          const m1 = getMoment(a.dueDate);
          const m2 = getMoment(b.dueDate);
          return m1?.isBefore(m2) ? 1 : m1?.isSame(m2) ? 0 : -1;
        });

        setTransactions(docs);
        setLoading(false);
      }, error => {
        console.error(error);
        setLoading(false);
      });
    }
  }, [user, userLoading]);

  return [transactions, loading];
};


const useTransaction = (id?: string): UseTransactionOutputType => {
  const firestore = firebase.firestore();
  const [user, userLoading] = useAuthState(firebase.auth());

  const [loading, setLoading] = useState(true);
  const [transaction, setTransaction] = useState<Transaction>();

  const col = firestore.collection('transactions');
  const ref = col.doc(id);

  useEffect(() => {
    if (!id) {
      setLoading(false);
    }
    else if (user && !userLoading) {
      return ref.onSnapshot(ref => {
        const doc = {
          _id: ref.id,
          ...ref.data(),
        } as Transaction;

        setTransaction(doc);
        setLoading(false);
      }, error => {
        setLoading(false);
      });
    }
  }, [user, userLoading, id]);

  const update = async (data: Transaction) => {
    setLoading(true);
    const { _id, ...upd } = data;
    await ref.update(upd);
    setLoading(false);
    return data;
  };

  const insert = async (data: Transaction) => {
    if (user) {
      setLoading(true);
      const { _id, ...upd } = data;
      upd.uid = user.uid;
      const out = await col.add(upd);
      const outDoc = await out.get();
      setLoading(false);
      return {
        _id: outDoc.id,
        ...outDoc.data(),
      } as Transaction;
    }
  };

  const deleteRecord = async () => {
    if (user) {
      setLoading(true);
      await ref.delete();
      setLoading(false);
      return true;
    }
    return false;
  };

  return {
    transaction,
    loading,
    update,
    insert,
    deleteRecord,
  };
};

export {
  useTransactions,
  useTransaction,
};
