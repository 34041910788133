import React from "react";

type OpenOverlayType = "ion-popover" | "ion-modal" | "ion-loading" | "ion-alert";
type UseOpenOverlayOutType<K extends keyof HTMLElementTagNameMap> = {
  getTop: () => HTMLElementTagNameMap[K] | null,
  dismissAll: () => Promise<number>,
  dismissTop: () => Promise<boolean>,
};

function useOpenOverlays<K extends OpenOverlayType>(appRef: React.RefObject<HTMLIonAppElement>, type: K): UseOpenOverlayOutType<K> {
  const getAllOverlays = (): HTMLElementTagNameMap[K][] => {
    const res: HTMLElementTagNameMap[K][] = [];
    if (appRef.current) {
      const items = appRef.current.querySelectorAll(type);
      for (let x = 0; x < items.length; x++) {
        res.push(items.item(x));
      }
    }
    return res;
  };

  const getTop = () => {
    const overlays = getAllOverlays();
    if (overlays && overlays.length > 0) {
      return overlays[overlays.length - 1];
    }
    return null;
  };

  const dismissTop = async () => {
    const overlay = getTop();
    if (overlay) {
      return overlay.dismiss();
    }
    return false;
  };

  const dismissAll = async () => {
    const overlays = getAllOverlays();
    let closed = 0;
    for (let i = 0; i < overlays.length; i++) {
      if (await overlays[i].dismiss()) {
        closed++;
      }
    }
    return closed;
  };

  return {
    getTop,
    dismissAll,
    dismissTop,
  };
};

export {
  useOpenOverlays,
};
