import { IonItemDivider, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { useContacts } from "../hooks/contacts";
import { Transaction } from "../hooks/transactions";
import TransactionItem from "./TransactionItem";

interface TransactionContainerProps {
  contactId?: string | null;
  transactions?: Transaction[];
}

const TransactionList: React.FC<TransactionContainerProps> = ({ contactId, transactions }) => {
  const [_1, contactsLoading, getContact] = useContacts();
  return (
    <IonList>
      {
        (contactId && !contactsLoading) && (
          <IonItemDivider sticky>
            <IonLabel>
              <h2>Transactions for {getContact(contactId)?.name}</h2>
            </IonLabel>
          </IonItemDivider>
        )
      }
      {
        transactions && transactions.map(rec => (
          <TransactionItem key={rec._id} data={rec} contact={getContact(rec.contactId)} showContactName={!contactId} />
        ))
      }
    </IonList>
  );
};

export default TransactionList;
