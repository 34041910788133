import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { IonReactRouter } from '@ionic/react-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Provider } from 'react-redux';
import { setupConfig } from '@ionic/react';
import config from './config';
import history from './state/history';
import configureStore from './state/store';

setupConfig({
  hardwareBackButton: false,
  swipeBackEnabled: false,
});

firebase.initializeApp(config);

const store = configureStore();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IonReactRouter history={history}>
        <App />
      </IonReactRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
