import { IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonModal, IonPage, IonPopover, IonProgressBar, IonTitle, IonToolbar, useIonPopover } from '@ionic/react';
import { addOutline, addSharp } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import ContactContainer from '../components/ContactContainer';
import ContactList from '../components/ContactList';
import { Contact, useContacts } from '../hooks/contacts';

const ContactsPage: React.FC = () => {
  const history = useHistory();
  const [contacts, contactsLoading] = useContacts();
  const [showPopover, setShowPopover] = useState<{
    show: boolean,
    data?: Contact | null,
  }>({
    show: false,
    data: null,
  });

  const handleAddContact = () => {
    setShowPopover({ show: true });
  };

  const handleEditContact = (data: Contact) => {
    setShowPopover({ show: true, data: { ...data } });
  };

  const handleCloseContact = (data?: Contact) => {
    setShowPopover({ show: false });
  };

  const handleShowTransactions = (id: string) => {
    history.push(`/contacts/${id}/transactions`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Contacts</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Contacts</IonTitle>
          </IonToolbar>
        </IonHeader>

        {contactsLoading && <IonProgressBar type="indeterminate" />}

        <IonModal
          isOpen={showPopover?.show}
          onDidDismiss={() => handleCloseContact()}
        >
          <ContactContainer
            id={showPopover.data?._id}
            onClose={handleCloseContact}
            onShowTransactions={handleShowTransactions}
          />
        </IonModal>

        <ContactList contacts={contacts} onClickContact={handleEditContact} />

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={handleAddContact} disabled={contactsLoading}>
            <IonIcon ios={addOutline} md={addSharp} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default ContactsPage;
