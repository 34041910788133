import { IonAvatar, IonImg, IonNote } from '@ionic/react';
import { settingsSharp } from 'ionicons/icons';

interface ContainerProps {
  name?: string;
}

const PreferencesContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className={'container'}>
      <IonAvatar>
        <IonImg src={settingsSharp} />
      </IonAvatar>
      <IonNote>Preferences</IonNote>
    </div>
  );
};

export default PreferencesContainer;
