import { IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, useIonModal } from "@ionic/react";
import { arrowDownOutline, arrowDownSharp, arrowUpOutline, arrowUpSharp } from "ionicons/icons";
import { Contact, useContacts } from "../hooks/contacts";
import { Transaction } from "../hooks/transactions";
import _ from 'lodash';
import ContactContainer from "./ContactContainer";
import moment from "moment";

interface TransactionFormProps {
  disabled: boolean;
  disableContact?: boolean;
  model: Transaction;
  onChange: (upd: Transaction) => void;
}

const TransactionForm: React.FC<TransactionFormProps> = ({ model, onChange, disabled, disableContact = false }) => {
  const [contacts, contactsLoading] = useContacts();

  const updateField = (name: keyof Transaction, value: any) => onChange({
    ...model,
    [name]: value
  });

  const isBorrowed = model.tranType === 'B';

  const [presentCreateContact, dismissCreateContact] = useIonModal(ContactContainer, {
    onClose: (newContact: Contact) => {
      if (newContact) {
        updateField('contactId', newContact._id);
      }
      dismissCreateContact();
    }
  });

  const handleContactChange = (newValue: any) => {
    if (newValue === 'new') {
      presentCreateContact();
    }
    else {
      updateField('contactId', newValue);
    }
  };

  const renderContacts = contacts ? contacts.map(c => (
    <IonSelectOption key={c._id} value={c._id}>{c.name}</IonSelectOption>
  )) : null;

  const maxDueDate = moment().add(15, 'y').endOf('y').format('YYYY-MM-DD');
  const maxTranDate = moment().format('YYYY-MM-DD');

  return (
    <IonList key={model._id} lines="full">
      <IonItem>
        <IonLabel position="stacked">Contact</IonLabel>
        {!contactsLoading ? (
          <IonSelect interface="popover" value={model.contactId} onIonChange={e => handleContactChange(e.detail.value)} disabled={disableContact || disabled || !!model._id}>
            {contacts && contacts.map(c => (
              <IonSelectOption key={c._id} value={c._id}>{c.name}</IonSelectOption>
            ))}
            <IonSelectOption value="new" className="font-style-italic">Add new</IonSelectOption>
          </IonSelect>
        ) : null}
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Type</IonLabel>
        <IonSelect interface="popover" value={model.tranType} onIonChange={e => updateField('tranType', e.detail.value)} disabled={disabled || !!model._id}>
          <IonSelectOption value="L">Lent</IonSelectOption>
          <IonSelectOption value="B">Borrowed</IonSelectOption>
        </IonSelect>
        <IonIcon
          slot="end"
          style={{ height: '40px' }}
          color={isBorrowed ? 'success' : 'danger'}
          ios={isBorrowed ? arrowDownOutline : arrowUpOutline}
          md={isBorrowed ? arrowDownSharp : arrowUpSharp}
        />
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Amount</IonLabel>
        <IonInput
          type="number"
          min="1"
          value={model.amount}
          onIonChange={e => updateField('amount', e.detail.value)}
          required
          disabled={disabled}
        />
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Date</IonLabel>
        <IonDatetime
          displayFormat="MMM D, YYYY"
          value={model.tranDate}
          onIonChange={e => updateField('tranDate', e.detail.value)}
          disabled={disabled}
          max={maxTranDate}
        />
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Due on</IonLabel>
        <IonDatetime
          displayFormat="MMM D, YYYY"
          value={model.dueDate}
          onIonChange={e => updateField('dueDate', e.detail.value)}
          disabled={disabled}
          max={maxDueDate}
        />
      </IonItem>

      <IonItem>
        <IonLabel position="stacked">Remarks</IonLabel>
        <IonInput
          type="text"
          value={model.remarks}
          onIonChange={e => updateField('remarks', e.detail.value)}
          disabled={disabled}
        />
      </IonItem>
    </IonList>
  )
};

export default TransactionForm;
