import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonText, isPlatform } from '@ionic/react';
import { arrowDownOutline, arrowDownSharp, arrowUpOutline, arrowUpSharp, callOutline, callSharp, caretDown, caretUp } from 'ionicons/icons';
import { Transaction } from '../hooks/transactions';
import { Contact } from '../hooks/contacts';
import { formatCurrency, getMoment } from '../utils/util';
import { CallNumber } from '@ionic-native/call-number';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router';

interface DueItemProps {
  data?: Transaction;
  contact?: Contact;
}

const DueItem: React.FC<DueItemProps> = ({ data, contact }) => {
  const location = useLocation();
  if (!data) {
    return null;
  }

  const isCordova = isPlatform('cordova');
  const handleCallClick = (event: React.MouseEvent, phone: string) => {
    event.stopPropagation();
    if (phone && isCordova) {
      event.preventDefault();
      CallNumber.callNumber(phone, false);
    }
  }

  const isBorrowed = data.tranType === 'B';
  const dueDate = getMoment(data.dueDate);
  const dueInfo = dueDate ? (dueDate.isSame(moment(), 'date') ? 'Due today' : moment().diff(dueDate, 'days') + ' days overdue') : '';

  return (
    <IonItem lines="full" button detail routerLink={`${location.pathname}/${data._id}`}>
      {/* <IonIcon
        slot="start"
        color={isBorrowed ? 'success' : 'danger'}
        ios={isBorrowed ? arrowDownOutline : arrowUpOutline}
        md={isBorrowed ? arrowDownSharp : arrowUpSharp}
      /> */}

      <IonLabel>
        <h1>
          {formatCurrency(data.amount)} <IonIcon color={isBorrowed ? 'success' : 'danger'} icon={isBorrowed ? caretDown : caretUp} />
        </h1>
        <p>{isBorrowed ? 'Borrowed from' : 'Lent to'} {contact?.name}</p>
        <p><IonText color="danger">{dueInfo}</IonText></p>
      </IonLabel>
      {
        contact?.phone && (
          <IonButtons slot="end">
            <IonButton color="tertiary" onMouseDown={event => event.stopPropagation()} onClick={event => handleCallClick(event, contact.phone!)} href={isCordova ? undefined : `tel:${contact.phone}`}>
              <IonIcon slot="icon-only" ios={callOutline} md={callSharp} />
            </IonButton>
          </IonButtons>
        )
      }
    </IonItem>
  );
};

export default DueItem;
