import { IonBackButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonMenuButton, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import { addOutline, addSharp } from 'ionicons/icons';
import React from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router';
import NoTransactions from '../components/NoTransacions';
import TransactionList from '../components/TransactionList';
import { useTransactions } from '../hooks/transactions';

const TransactionsPage: React.FC = () => {
  const { contactId } = useParams<{ contactId?: string }>();
  const location = useLocation();
  const [transactions, transactionsLoading] = useTransactions(contactId ? [['contactId', '==', contactId]] : undefined);

  const parentPath = location.pathname.startsWith('/contacts')
    ? '/contacts'
    : location.pathname.startsWith('/dashboard')
      ? '/dashboard'
      : null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {
              parentPath ? (
                <IonBackButton defaultHref={parentPath} />
              ) : (
                <IonMenuButton />
              )
            }
          </IonButtons>
          <IonTitle>Transactions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Transactions</IonTitle>
          </IonToolbar>
        </IonHeader>

        {
          transactionsLoading ? (
            <IonProgressBar type="indeterminate" />
          ) : (
            <React.Fragment>
              <TransactionList contactId={contactId} transactions={transactions} />
              {
                (!transactions || transactions.length === 0) && (
                  <NoTransactions />
                )
              }
            </React.Fragment>
          )
        }

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink={`${location.pathname}/new`} disabled={transactionsLoading}>
            <IonIcon ios={addOutline} md={addSharp} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default TransactionsPage;
