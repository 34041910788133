import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import moment from 'moment';
import DueList from '../components/DueList';
import NoTransactions from '../components/NoTransacions';
import { useTransactions } from '../hooks/transactions';

const DuesPage: React.FC = () => {
  const [transactions, transactionsLoading] = useTransactions([['dueDate', '<=', moment().endOf('day').toDate()]]);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Dues</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Dues</IonTitle>
          </IonToolbar>
        </IonHeader>
        {
          transactionsLoading ? (
            <IonProgressBar type="indeterminate" />
          ) : (
            (transactions && transactions.length > 0) ? (
              <DueList transactions={transactions} />
            ) : (
              <NoTransactions message="No transactions due" />
            )
          )
        }
      </IonContent>
    </IonPage>
  );
};

export default DuesPage;
